<template>
  <section ref="sobre" id="sobre-page">

<div class="quem-somos">
      <h1 class="title-size">Quem Somos?</h1>

    <p class="size-text specific-desciption">Somos três amigas que nos conhecemos há anos e convivemos juntas.
         Nós sempre pensamos em abrir algo que beneficiaria as pessoas, e nos útimos anos viemos refinando esta ideia, até que,
         surge a Maturité!
         </p>
</div>


<div class="nosso-trabalho">
    <h1 class="title-size">Nosso trabalho</h1>
    <p class="size-text specific-desciption">Três amigas, com um sonho em comum de trazer qualidade de vida para pessoas, através da conversa, profissionalismo e muita diversão!</p>

</div>
<div class="cada-uma-de-nos">
    
    <h1 class="title-size">Cada uma de nós</h1>
<div class="imgs-solo">

    <div class="div-funcionario">
    <div class="funcionario">
          <img class="denise" src="../assets/heart-sobre.png" alt="Denise">
      </div>
            <p v-html="textDenise" class="size-text back-perso"></p>
    </div>

      <div class="div-funcionario">
          <div class="funcionario">
          <img class="Katya" src="../assets/heart-sobre.png" alt="Katya">
        </div>
          <p v-html="textKatya" class="size-text back-perso"></p>
      </div>

    <div class="div-funcionario">
      <div class="funcionario">
          <img class="beth" src="../assets/heart-sobre.png" alt="Beth">
      </div>
          <p v-html="textBeth" class="size-text back-perso"></p>
    </div>
      

</div>
</div>
      

  </section>
</template>

<script>
export default {
    name: "Sobre-main",
    data (){
        return{
            textBeth: "",
            textKatya: "",
            textDenise: ""
        }
    },
    created(){
        this.textKatya = "<strong class='title-size'>Katya Tomanik</strong><ul class='left-list'>" +
        "<li>Profissional de educação física</li>" +
        "<li>Pós graduada em gerontologia</li>" +
        "<li>Pós graduada em condicionamento físico de reabilitação cardíaca</li>" +
        "<li>Experiência com idosos</li></ul>"

        this.textDenise = "<strong class='title-size'>Denise Saito</strong><ul class='left-list'>" +
        "<li>Enfermeira há 30 anos</li>" +
        "<li>Vivência em clínica médica e saúde do adulto</li>" +
        "<li>Enfermeira do trabalho</li>" +
        "<li>Docente na área</li>" 

        this.textBeth = "<strong class='title-size'>Elizabeth Merz</strong><ul class='left-list'>" +
        "<li>Psicóloga há mais de 30 anos</li>" +
        "<li>Experiência em marketing</li>" +
        "<li>Experiência em atendimento ao cliente</li>" 
    }
}/*Denise Saito - Enfermeira há 30 anos. Vivência em clínica médica, saúde do adulto, enfermeira do trabalho e docente em enfermagem.
Elizabeth Merz - Psicóloga há mais de 30 anos, experiência em marketing e atendimento ao cliente.
Katya Tomanik - Profissional de educação física, pós graduada em gerontologia, e condicionamento físico de reabilitação cardíaca. Experiência com idosos */
</script>

<style>
    .left-list{
        list-style:circle;
    }

    .specific-desciption{
        margin: auto auto;
        width: 80%;
    }

    .div-funcionario{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        min-width: 300px;
        width: 21.34vw;
    }

    .back-perso{
        background: linear-gradient(var(--background-gray), #bbbbbb);
        padding-bottom: 2vw;
    }

    #sobre-page{
        background: var(--background-gray);
    }

    #sobre-page h1{
        color: var(--orange-color);
        font-family: var(--font-title);
        padding: 10px 0 10px 0;
        
        text-align: center;
    }

    #sobre-page p{
        font-family: 'Times New Roman', Times, serif;
        text-align: center;
    }

    .pic-of-all{
        background: var(--background-gray);
    }

    .quem-somos{
        background: var(--background-gray);
        color: black;
        padding-bottom: 2.5vw;
    }

    .imgs-solo{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        background: var(--background-gray);
        border-bottom: 2px dashed black;
        padding-bottom: 5vw;
    }

    .nosso-trabalho{
        background: black;
        color: white;
        padding-bottom: 2.5vw;
    }

    .funcionario{
        overflow: hidden;
        width: 100%;
        height: 15vw;
        margin: 2vw 0;

        display: flex;
        justify-content: center;
        align-items: center;
        
        min-width: 300px;
        min-height: 210px;

        /*-webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;*/
    }

    .cada-uma-de-nos{
        background: var(--background-gray);
    }

    /*.funcionario:hover{
        cursor: pointer;
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        border-color: black;
    }*/

    .funcionario img {
        width: 100%;
        height: 100%;
    }

    .Katya{
        background:var(--orange-color)  url('../assets/Katya.png') no-repeat;
        background-position: right 60% bottom -400%;
        background-size: 45%;
        /*height: 105%;
        width: 70%;*/
    }
    
    .denise{
        background:var(--orange-color)  url('../assets/Denise.png') no-repeat;
        background-position: right 20% bottom -450%;
        background-size: 100%;
        /*height: 100%;
        width: 150%;*/
    }

    .beth{
        background: var(--orange-color) url('../assets/Beth.png') no-repeat;
        background-position: right 60% bottom -350%;
        background-size: 45%;
        /*height: 105%;
        width: 70%;*/
    }

    @media screen and  (max-width: 900px) {
    }

    @media screen and  (max-width: 600px){
    }

</style>